<div class="card-edit-popup js-card-edit-popup">
  <div class="card-edit-popup__wrapper">
    <div class="card-edit-popup__top">
      <div class="card-edit-popup__title">Редактирование контакта</div>
    </div>
    <div class="card-edit-popup__hr"></div>
    <div class="card-edit-popup__full-info">
      <div class="card-edit-popup__edit-photo">
        <img class="images card-edit-popup__photo" src="{{data.user.avatar}}" alt="person">
        <!-- <input type="file"
               #file
               [multiple]="false"
               (change)="cropImagePopupDialog(data.user, $event)"
               style="display:none" />
        <div (click)="file.click()" class="card-edit-popup__chose-photo"
        style="
        background-color: rgb(43 43 43 / 43%) !important;
        border-radius: 50% !important;
        display: hidden !important;
        width: 50px !important;
        height: 50px !important;"
        alt="chose">
        </div> -->
      </div>
      <div class="card-edit-popup__infos">
        <label>
          <span class="card-edit-popup__label">Фамилия:</span>
          <input disabled [(ngModel)]="userReg.lastName" type="text" class="card-edit-popup__input">
        </label>
        <label>
          <span class="card-edit-popup__label">Имя:</span>
          <input disabled [(ngModel)]="userReg.firstName" #firstName type="text" class="card-edit-popup__input">
        </label>
        <label>
          <span class="card-edit-popup__label">Отчество</span>
          <input disabled [(ngModel)]="userReg.patronymic" #patronymic type="text" class="card-edit-popup__input">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__others-info">
      <div class="card-edit-popup__other-block" *ngIf='Role === "\"Administrator\""'>
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/hove-svg.svg" alt="home">
        </div>
        <div class="card-edit-popup__selectors">
          <div class="card-edit-popup__selector">
            <div class="card-edit-popup__label">
              Должность:
            </div>
            <mat-select [(ngModel)]="userReg.claimedRole">
              <mat-option *ngFor="let role of roles" value="{{role}}">{{role}}</mat-option>
            </mat-select>
          </div>
          <div class="card-edit-popup__selector">
            <div class="card-edit-popup__label">
              Компания:
            </div>
            <mat-select [(ngModel)]="userReg.organizationId">
              <mat-option *ngFor="let organization of data.organizations"
                          [value]="organization.id">{{organization.name}}</mat-option>
            </mat-select>
          </div>
        </div>

      </div>
      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/phone-svg.svg" alt="home">
        </div>
        <label>
          <span class="card-edit-popup__label">Контактный телефон:</span>
          <input disabled #phone type="tel" class="card-edit-popup__input" prefix="+7 " mask="(000) 000-00-00"
                 [validation]="true" [(ngModel)]="userReg.phone">
        </label>

      </div>

      <div class="card-edit-popup__other-block-contact" *ngIf='Role === "\"Administrator\"" || userReg.phone === Phone'>
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/password-2-svgrepo-com.svg" alt="home">
        </div>
        <label>
          <span class="card-edit-popup__label">Пароль:</span>
          <input placeholder="Новый пароль" type="text" class="card-edit-popup__input"
                  [(ngModel)]="userReg.password">
        </label>

      </div>

      <div class="card-edit-popup__other-block-contact">
        <div class="card-user-popup__icon-link">
          <img class="images card-user-popup__icon-link-img" src="img/main-svg.svg" alt="home">
        </div>
        <label>
          <span class="card-edit-popup__label">Email:</span>
          <input disabled #email type="email" class="card-edit-popup__input" [(ngModel)]="userReg.email">
        </label>
      </div>
    </div>
    <div class="card-edit-popup__bottoms">
      <a class="card-edit-popup__delete-btn js-card-edit-popup__delete-btn" (click)="deleteAccount(data.user.id)"
         mat-dialog-close>
        <img class="card-edit-popup__basket-img" src="img/card-edit-popup__-basket.svg" alt="img">
        <span class="card-edit-popup__basket-span">Удалить сотрудника</span>
      </a>
      <div class="card-edit-popup__actions">
        <button class="card-edit-popup__removal js-card-edit-popup__removal" mat-dialog-close>Отмена</button>
        <button class="card-edit-popup__conservation js-card-edit-popup__conservation" (click)="changeAccount()"
                mat-dialog-close>Сохранить
        </button>
      </div>
    </div>
  </div>
</div>
