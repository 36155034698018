<div class="wrapper">
  <div class="content">
    <p class="title" (click)="open()">{{alert.title}}</p>
    <div>
      <button (click)="delete.emit()" mat-mini-fab color="warn"
              aria-label="Example icon button with a filter list icon">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-mini-fab color="primary"
                aria-label="Example icon button with a filter list icon">
          <mat-icon (click)="open()" *ngIf="!isSelected">edit</mat-icon>
          <mat-icon (click)="update.emit()" *ngIf="isSelected">save</mat-icon>
      </button>
      <button mat-mini-fab color="accent"
              aria-label="Example icon button with a filter list icon">
        <mat-icon title="Отметить как прочитанную" (click)="setDate.emit()" *ngIf="alert.readDates.length === 0">book</mat-icon>
        <mat-icon title="Новость прочитана" *ngIf="alert.readDates.length > 0">check</mat-icon>
      </button>
    </div>
  </div>
  <p class="text">{{alert.text}}</p>
  <span class="date" title="Дата создания">{{formattedDate(alert.creationDate)}}</span>
  <div class="animated" *ngIf="isSelected">
  <div class="form_group field">
    <input [(ngModel)]="alert.title" type="text" class="form_field" placeholder="Name" name="name" id='name' />
    <label class="form_label">Название</label>
  </div>
    <div class="form_group field">
      <textarea [(ngModel)]="alert.text" type="text" class="form_field_textarea" placeholder="Name" name="name" id='name'></textarea>
      <label class="form_label">Тема</label>
    </div>
  </div>
</div>
