<div class="report-pass-popup js-add-pass-popup">
  <div class="add-pass-popup__wrapper">
    <div class="add-pass-popup__top">
      <div class="add-pass-popup__top-block">
        <div class="add-pass-popup__title">Настройки приложения</div>
        <!-- <div class="add-pass-popup__when">от 22 Ареля, 2021</div> -->
      </div>
      <div class="add-pass-popup__exit js-add-pass-popup__exit" mat-dialog-close>
        <svg>
          <use xlink:href="img/icons/icons.svg#exit-cross"></use>
        </svg>
      </div>
    </div>
    <div class="add-pass-popup__bottom">
    </div>
    <div class="add-pass-popup__main overflow-auto">
      <div class="add-pass-popup__info-guest">

        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              Регистрация
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Настройки регистрации
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="position: relative; display: flex; flex-direction: column;">
            <mat-checkbox class="example-margin" [(ngModel)]="appSettings.phoneNumberVerification">Требовать
              подтверждение номера телефона
            </mat-checkbox>
            <mat-checkbox class="example-margin" [(ngModel)]="appSettings.verificationByPhoneCall">Подтверждение
              звонком
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              Домен
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Настройки доменного имени
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">Доменное имя</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.appUrl"
                   placeholder="https://example.com/">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.appUrl">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              IP адрес FireBird
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Настройки IP адреса
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">IP адрес</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.fireBirdIp"
                   placeholder="27.732.21.20">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.fireBirdIp">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              Сбербанк
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Настройки СБЕР API
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">URL</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.aquaringUrl"
                   placeholder="https://securepayments.sberbank.ru/payment/rest/">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.aquaringUrl">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.aquaringLigin"
                   placeholder="564654654699-api">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.aquaringLigin">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.aquaringPassword"
                   placeholder="password">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.aquaringPassword">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              SMTP
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Конфигурация почтового клиента
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">Хост</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.smtpHost"
                   placeholder="smtp.yandex.ru">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.smtpHost">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span style="color: #fff;" class="add-pass-popup__span-title">Порт</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.smtpPort" placeholder="587">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.smtpPort">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span style="color: #fff;" class="add-pass-popup__span-title">Email</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.smtpSenderEmail"
                   placeholder="info@spkb.ml">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.smtpSenderEmail">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <span style="color: #fff;" class="add-pass-popup__span-title">Пароль</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.smtpPassword"
                   placeholder="****">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.smtpPassword">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              SMS.RU
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Интеграция с сервисом sms.ru
            </mat-panel-description>
          </mat-expansion-panel-header>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title">Api key</span>
          <div style="position: relative;">
            <input class="security-page__arrive-input apiInput" [(ngModel)]="appSettings.smsRuApi"
                   placeholder="PublickKey">
            <button mat-raised-button class="copyLongButton" [cdkCopyToClipboard]="appSettings.smsRuApi">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel style="height: 100%; margin-top: 3%; color: #fff;" hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title style="color: #fff;">
              График работы
            </mat-panel-title>
            <mat-panel-description style="color: #fff;">
              Настройка графика по умолчанию
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div style="position: relative;">
            <mat-checkbox
              [(ngModel)]="appSettings.schedule.isShiftMode" matTooltip="Режим таймера"
              class="timer-schekbox">
              По сменам
            </mat-checkbox>
            <span class="card-title" *ngIf="appSettings.schedule.isShiftMode">Смены</span>
            <span class="card-title" *ngIf="!appSettings.schedule.isShiftMode">Таймер</span>
          </div>

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title"
                *ngIf="appSettings.schedule.isShiftMode">Начало первой смены</span>

          <input [ngxMatTimepicker]="picker" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="appSettings.schedule.isShiftMode"
                 [format]="24"
                 readonly [(ngModel)]="appSettings.schedule.firstShift">
          <ngx-mat-timepicker #picker></ngx-mat-timepicker>

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title"
                *ngIf="appSettings.schedule.isShiftMode">Начало второй смены</span>
          <input [ngxMatTimepicker]="picker2" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="appSettings.schedule.isShiftMode"
                 [format]="24"
                 readonly [(ngModel)]="appSettings.schedule.secondShift">

          <ngx-mat-timepicker #picker2></ngx-mat-timepicker>
          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title"
                *ngIf="appSettings.schedule.isShiftMode">Начало третей смены</span>
          <input [ngxMatTimepicker]="picker3" class="security-page__arrive-input" placeholder="00:00"
                 *ngIf="appSettings.schedule.isShiftMode"
                 [format]="24"
                 readonly [(ngModel)]="appSettings.schedule.thirdShift">

          <span style="margin-top: 2%; color: #fff;" class="add-pass-popup__span-title"
                *ngIf="!appSettings.schedule.isShiftMode">Продолжительность сесии (часов)</span>
          <input type="number" class="security-page__arrive-input" placeholder="8"
                 *ngIf="!appSettings.schedule.isShiftMode" min="0" max="24"
                 [(ngModel)]="appSettings.schedule.timerHours">
          <ngx-mat-timepicker #picker3></ngx-mat-timepicker>

          <!-- <div style="position: relative;">
            <mat-checkbox
                      [(ngModel)]="appSettings.schedule.defaultParameters"
                      style="margin: auto; margin-top: 3%;">
                      Стандартные настройки
            </mat-checkbox>
          </div>   -->
        </mat-expansion-panel>
        <!-- export interface ScheduleForSend{
          firstShift: string;
          secondShift: string;
          thirdShift: string;
          isTimerMode: boolean;
          timerHours: number;
          defaultParameters: boolean;
      } -->
        <mat-card style="margin-top: 3%;">
          <div>
            <button mat-raised-button style="
    width: 52%; background-color: #ea5c0a; color: rgb(255, 255, 255);" matTooltip="Сохранить" class="mt-3"
                    (click)="saveSettings()">
              <mat-icon>save</mat-icon>
            </button>
            <button mat-raised-button style="
    width: 20%; margin-left: 4%; background-color: #464646a3; color: rgb(255, 255, 255);"
                    matTooltip="Перезапуск компонента отвечающего за обновления в реальном времени" class="mt-3"
                    (click)="reconnectToSignalR()">
              <mat-icon>restart_alt</mat-icon>
            </button>
            <button mat-raised-button style="
    width: 20%; margin-left: 4%; background-color: #464646; color: rgb(255, 255, 255);"
                    matTooltip="Перезапуск приложения" class="mt-3" (click)="shutdown()">
              <mat-icon>power_settings_new</mat-icon>
            </button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
