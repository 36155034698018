import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ApiComponent} from './api/api.component';
import {AuthGuard} from './auth.guard';
import {EmailConfirmationComponent} from './email-confirmation/email-confirmation.component';
import {EntranceNewPasswordComponent} from './entrance-new-password/entrance-new-password.component';
import {EntranceSmsComponent} from './entrance-sms/entrance-sms.component';
import {EntranceComponent} from './entrance/entrance.component';
import {PaymentQrComponent} from './finance/payment-qr/payment-qr.component';
import {ShopUsersComponent} from './finance/shop-users/shop-users.component';
import {HeaderComponent, UserEditPopupDialog} from './header/header.component';
import {Roles} from './models/roles.moel';
import {MyPassPageComponent} from './my-pass-page/my-pass-page.component';
import {ConditionsPageComponent} from './order/conditions-page/conditions-page.component';
import {NewPaymentComponent} from './order/new-payment/new-payment.component';
import {OrderAquaringsComponent} from './order/order-aquarings/order-aquarings.component';
import {OrderComponent} from './order/order/order.component';
import {OrdersComponent} from './order/orders/orders.component';
import {PayComponent} from './order/pay/pay.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ProductsComponent} from './product/products/products.component';
import {SendMailComponent} from './send-mail/send-mail.component';
import {
  UsersListComponent,
  DialogContentExampleDialog,
  AddOrganizationPopupDialog,
  CardEditPopupDialog
} from './users-list/users-list.component';
import {WaitingForSubmitComponent} from './waiting-for-submit/waiting-for-submit.component';
import {NewsEditorComponent} from "./news-editor/news-editor.component";

const routes: Routes = [
  {path: '', component: MyPassPageComponent, pathMatch: 'full'},
  {
    path: 'users-list', component: UsersListComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer]
    }
  },
  {
    path: 'my-pass-page/:id', component: MyPassPageComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer]
    }
  },
  {
    path: 'my-pass-page', component: MyPassPageComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer]
    }
  },
  {
    path: 'news-editor', component: NewsEditorComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer]
    }
  },
  {
    path: 'entrance', component: EntranceComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Guest]
    }
  },
  {
    path: 'entrance-sms', component: EntranceSmsComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer, Roles.Guest, Roles.Candidate]
    }
  },
  {
    path: 'entrance-new-password', component: EntranceNewPasswordComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer]
    }
  },
  // { path: 'user-edit-popup', component: UserEditPopupDialog },
  {
    path: 'waiting-for-submit', component: WaitingForSubmitComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Candidate]
    }
  },
  {
    path: 'integration', component: ApiComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer, Roles.Candidate]
    }
  },
  {
    path: 'header', component: HeaderComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer, Roles.Candidate]
    }
  },
  {
    path: 'payment-qr', component: PaymentQrComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.Administrator]
    }
  },
  {
    path: 'shop-users', component: ShopUsersComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.Administrator]
    }
  },
  {
    path: 'new-payment', component: NewPaymentComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer, Roles.Candidate]
    }
  },
  {
    path: 'orders', component: OrdersComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.Administrator]
    }
  },
  {
    path: 'aquaring', component: OrderAquaringsComponent,
    canActivate: [AuthGuard],
    data: {
      userRoles: [Roles.Director, Roles.SecurityOfficer, Roles.Administrator, Roles.SeniorManager, Roles.Manager, Roles.SeniorSecurityOfficer, Roles.SecurityOfficer, Roles.Candidate]
    }
  },
  {path: 'pay', component: PayComponent},
  {path: 'condition', component: ConditionsPageComponent},
  {path: 'products', component: ProductsComponent},
  {path: 'order/:id', component: OrderComponent},
  {path: 'email-confirmation/:id', component: EmailConfirmationComponent},
  {path: "robots.txt", redirectTo: '../assets/robots.txt'},
  {
    path: ".well-known/pki-validation/8488023BAE8E662D7E423F36B85D4897.txt",
    redirectTo: '../assets/8488023BAE8E662D7E423F36B85D4897.txt'
  },
  {path: '**', component: PageNotFoundComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    enableTracing: true
  })],
  // imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
