<div class="wrapper">
  <div class="content">
    <p class="title">{{data.title}}</p>
    <div>
      <button (click)="markAsRead()"  mat-raised-button color="warn">Отметить о прочтении</button>
    </div>
  </div>
  <p class="text">{{data.text}}</p>
  <span title="Дата создания">{{(formattedDate(data.creationDate))}}</span>
</div>
