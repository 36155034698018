import {Component, OnInit} from "@angular/core";
import {AlertService} from "./alert.service";
import {Alert} from "./alert";
import {Clipboard} from '@angular/cdk/clipboard';
import {ReadDate} from "./readDate";
import {ReadDateService} from "./readDate.service"
import moment from "moment";
import {DialogWindowComponent} from "../dialog-window/dialog-window.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-news-editor',
  templateUrl: './news-editor.component.html',
  styleUrls: ['./news-editor.component.css'],
  providers: [AlertService, ReadDateService],
})

export class NewsEditorComponent implements OnInit {
  ngOnInit(): void {
    this.getData();
  }

  alerts: Alert[] = [];

  readDates: ReadDate[] = [];

  newAlert: Alert = {
    title: "",
    text: "",
    alertId: 0,
    creationDate: moment(Date.now()).format('yyyy/MM/DD  HH:mm'),
    readDates: '',
    userId: 0
  };

  newReadData: ReadDate = {
    // date: moment(Date.now()).format('yyyy/MM/DD  HH:mm')
    date: '',
  }

  constructor(private alertService: AlertService, private readService: ReadDateService, private clipboard: Clipboard) {
  }

  copyValue(data: string) {
    this.clipboard.copy(data)
  }

  submit() {
    if (this.newAlert?.title === '' && this.newAlert?.text === '') {
      alert("Вы ничего не написали!");
    } else {
      this.alertService.postData(this.newAlert)
        .subscribe({
          next: (data) => {
            this.newAlert = data;
            this.alerts.push(this.newAlert);
            this.newAlert.text = '';
            this.newAlert.title = '';
            this.getData();
          },
          error: error => console.log(error)
        });
    }
  }

  setDate(index: number) {
    console.log(this.alerts[index]);
    this.readService.getReadDate(this.alerts[index].alertId).subscribe({
      next: (data: any) => {
        console.log(data);
        this.newReadData = <ReadDate>data;
        this.readDates.push(this.newReadData);
        this.alerts[index].readDates = this.newReadData.date;
      }
    });
    console.log(this.newReadData);
  }

  deleteAlert(index: number) {
    this.alertService.delete(this.alerts[index].alertId).subscribe({
      next: () => {
        this.alerts.splice(index, 1);
        this.deleteReadDate(index);
      }
    })
  }

  deleteReadDate(index: number) {
    this.readService.delete(this.alerts[index].userId).subscribe({
      next: () => {
        console.log("The readDate is deleted");
      }
    })
  }

  updateAlert(index: number, data: Alert) {
    this.alertService.update(this.alerts[index].alertId, data).subscribe({
      next: (data) => {
        this.alerts[index] = <Alert>data;
        // this.currentAlert = <Alert>data;
      }
    })
  }

  getData() {
    this.alertService.getData()
      .subscribe({
        next: (data) => {
          this.alerts = data;
          console.log(`The alert's title: ${data[0].title}`);
        },
        error: error => console.log(error)
      })
  }
}



