<header>
  <div class="header__wrapper">
    <div class="header__top">
      <div class="header__logo-block" [routerLink]="['/my-pass-page']">
        <a class="header__logo-link">
          <img class="header__logo-img" src="img/logo.png" alt="logo">
        </a>
      </div>
      <nav class="header__navigation" *ngIf="user.role != 'Candidate'">
        <ul class="header__list">
          <li class="header__item">
            <a [routerLink]="['/users-list']" class="header__nav-link">
              <mat-icon matBadge="{{candidates}}" [matBadgeHidden]="user.role != 'SeniorSecurityOfficer'">
                <img class="header__icon-list" src="img/users.svg" alt="icon">
              </mat-icon>
              <p class="header__nav-text">пользователи</p>
            </a>
          </li>
          <li class="header__item">
            <a [routerLink]="['/my-pass-page']" class="header__nav-link">
              <img class="header__icon-list" src="img/key.svg" alt="icon">
              <p class="header__nav-text">Пропуска</p>
            </a>
          </li>
<!--          <li class="header__item">-->
<!--            <a [routerLink]="['/news-editor']" class="header__nav-link">-->
<!--              <img class="header__icon-list" src="img/notification.svg" alt="icon">-->
<!--              <p class="header__nav-text">Инфо</p>-->
<!--            </a>-->
<!--          </li>-->
          <li class="header__item" *ngIf="user.role == 'Administrator'" [matMenuTriggerFor]="paymentMenu">
            <a class="header__nav-link">
              <!-- <img class="header__icon-list" src="img/key.svg" alt="icon"> -->
              <mat-icon>payments</mat-icon>
              <p class="header__nav-text">Финансы</p>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="header__bottom" [matMenuTriggerFor]="aboveMenu">
      <div class="header__whose-account js-open-editor">
        <img class="header__whose-account-img" src="{{user.avatar}}" alt="person">
      </div>
      <div class="header__bottom-text">аккаунт</div>
    </div>
  </div>
</header>
<mat-menu #aboveMenu="matMenu" yPosition="above">
  <mat-card class="balance" *ngIf="user.role == 'Administrator'" (click)="openSmsRu()" matTooltip="Баланс на sms.ru"
            style="color:wheat">
    <!-- <mat-icon class="balance-icon">payments</mat-icon> -->
    Баланс {{balance}} ₽
  </mat-card>
  <button mat-menu-item (click)="applicationSettingsComponent()" *ngIf="user.role == 'Administrator'">
    <mat-icon>settings_applications</mat-icon>
    Настройки приложения
  </button>
  <button mat-menu-item (click)="addToHomeScreen()" *ngIf="showButton && deviceService.os != 'iOS'">
    <mat-icon>add_box</mat-icon>
    Добавить на робочий стол
  </button>
  <!-- <button mat-menu-item (click)="addToHomeScreen()" *ngIf="showButton && deviceService.os == 'Windows'">
      <mat-icon>add_box</mat-icon> Добавить на робочий стол
  </button> -->
  <button mat-menu-item (click)="addToHomeScreenIOS()" *ngIf="deviceService.os == 'iOS'">
    <mat-icon>add_box</mat-icon>
    На экран "Домой"
  </button>
  <!-- <button mat-menu-item (click)="importExportComponent()" *ngIf="user.role == 'Administrator'">
      <mat-icon>import_export</mat-icon> Импорт | Експорт
  </button> -->
  <button mat-menu-item (click)="reportComponent()" *ngIf="user.role == 'SeniorSecurityOfficer'">
    <mat-icon>content_paste</mat-icon>
    Отчёт
  </button>
  <button mat-menu-item (click)="scheduleComponent()" matTooltip="Настройка продолжительности сесии">
    <mat-icon>schedule</mat-icon>
    График работы
  </button>
  <button mat-menu-item (click)="alertsComponent()" matTooltip="Настройка уведомлений на электронную почту">
    <mat-icon>notifications</mat-icon>
    Уведомления
  </button>
  <button mat-menu-item (click)="sendMailWithChooseUserComponent()"
          matTooltip="Отправить сообщение на электронную почту">
    <mat-icon>email</mat-icon>
    Почта
  </button>
  <button mat-menu-item [routerLink]="['/integration']">
    <mat-icon>api</mat-icon>
    Интеграция
  </button>
  <!-- <button mat-menu-item (click)="openSwagger()" >
      <mat-icon>integration_instructions</mat-icon> Swagger
  </button> -->
  <button mat-menu-item (click)="documentationComponent()" matTooltip="Скачать PDF инструкцию по использованию сервиса">
    <mat-icon>assignment</mat-icon>
    Документация
  </button>
  <button mat-menu-item (click)="userEditPopupDialog()">
    <mat-icon>account_circle</mat-icon>
    Настройки уккаунта
  </button>
  <button mat-menu-item (click)="logOut()">
    <mat-icon>logout</mat-icon>
    Выход
  </button>
</mat-menu>
<mat-menu #paymentMenu="matMenu" yPosition="above">
  <button mat-menu-item [routerLink]="['/payment-qr']">
    <mat-icon class="balance-icon">payments</mat-icon>
    Платежи
  </button>
  <!-- <button mat-menu-item [routerLink]="['/new-payment']">
      <mat-icon class="balance-icon">add</mat-icon> Новый платёж
  </button> -->
  <button mat-menu-item [routerLink]="['/aquaring']">
    <mat-icon class="balance-icon">format_list_bulleted</mat-icon>
    Эквайринг
  </button>
  <button mat-menu-item [routerLink]="['/orders']">
    <mat-icon class="balance-icon">format_list_bulleted</mat-icon>
    Платежи СБЕР
  </button>
  <button mat-menu-item [routerLink]="['/shop-users']">
    <mat-icon class="balance-icon">groups</mat-icon>
    Пользователи магазина
  </button>
</mat-menu>
