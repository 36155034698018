import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Alert} from "./alert";

@Injectable({providedIn: 'root'})
export class AlertService {
  constructor(private http: HttpClient){ }

  postData(alert: Alert){
    console.log(alert);
    const body = {title: alert.title, text: alert.text};
    return this.http.post<Alert>("/api/Alert/postAlert", body);
  }

  delete(id:number) {
    return this.http.delete<void>(`/api/Alert/deleteAlert${id}`);
  }

  update(id: number, alert: Alert) {
    const body = {title: alert.title, text: alert.text};
    return this.http.put<Alert>(`/api/Alert/updateAlert${id}`, body);
  }

  getData() {
    return this.http.get<Alert[]>("/api/Alert/allAlerts");
  }

  getUnreadedData() {
    return this.http.get<Alert[]>("/api/Alert/unReadAlerts");
  }
}
