import {Component, EventEmitter, Input, NgIterable, OnInit, Output} from '@angular/core';
import {Alert} from "../news-editor/alert";
import moment from "moment";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @Input() alert!: Alert;
  // @Input() currentAlert!: Alert;
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() update: EventEmitter<void> = new EventEmitter<void>();
  @Output() setDate: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }
  isSelected = false;
  ngOnInit(): void {
  }
  open() {
    this.isSelected = !this.isSelected;
  }

   formattedDate = (data:string) => {
    return moment(data).format('yyyy/MM/DD  HH:mm');
  }

  date() {
    // console.log(moment(Date.now()).format('yyyy/MM/DD  HH:mm'));
  }
}
