import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Alert} from "../news-editor/alert";
import {ReadDateService} from "../news-editor/readDate.service";
import moment from "moment/moment";
// import {User} from "../models/user.model";

@Component({
  selector: 'app-dialog-window',
  templateUrl: './dialog-window.component.html',
  styleUrls: ['./dialog-window.component.css'],
})
export class DialogWindowComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: Alert, public dialogRef: MatDialogRef<void>, private readService: ReadDateService) { }

  markAsRead() {
    this.readService.getReadDate(this.data.alertId).subscribe(() => {
    this.dialogRef.close();
    });
  }

  formattedDate = (data:string) => {
    return moment(data).format('yyyy/MM/DD  HH:mm');
  }

  ngOnInit(): void {
  }
}
