import {HttpClient} from '@angular/common/http';
import {ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ImageCroppedEvent, ImageTransform, Dimensions} from 'ngx-image-cropper';
import {ApiComponent} from '../api/api.component';
import {AppComponent} from '../app.component';
import {DocumentationComponent} from '../documentation/documentation.component';
import {LocalStorageService} from '../local-storage.service';
import {Organization} from '../models/organization.model';
import {Roles} from '../models/roles.moel';
import {User, UserForChange, UserPublicData} from '../models/user.model';
import {ReportComponent} from '../report/report.component';
import {SignalRService} from '../signal-r.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user!: UserPublicData;
  public balance: string = "0";
  public organizations: Organization[] = [];
  public candidates: number = 0;
  deferredPrompt: any;
  showButton = false;

  constructor(private _snackBar: MatSnackBar, public dialog: MatDialog, public http: HttpClient, private router: Router, public localStorag: LocalStorageService, private appComponent: AppComponent, public signalr: SignalRService, private ref: ChangeDetectorRef, public deviceService: DeviceDetectorService
  ) {
    this.signalr.onconnected.subscribe(() => {
      if (this.localStorag.get("SignalR") == HubConnectionState.Connected) {
        this.subscribeOnSignalR();
      }
    });
    // if(this.localStorag.get("SignalR") == HubConnectionState.Connected){
    //   this.subscribeOnSignalR();
    // }
    this.http.get<UserPublicData>("/Account/DataAboutMyAccount", {}).subscribe((result) => {
      this.user = result;
      this.localStorag.set("user", result);
      this.localStorag.set("role", result.role);
      if (this.user.role == Roles.Administrator) {
        this.http.get<string>('/AppConfiguration/GetBalance', {}).subscribe((result) => {
          this.balance = result;
        });
      }
      this.ref.detectChanges();
    });
    // this.signalr.connection.onclose = () => {
    //   this.signalr.connection.off("GetPrivateToken");
    //   this.signalr.connection.off("SendPrivateUserGet");
    // }
  }

  ngOnInit(): void {

    console.log(this.deviceService.os);
    this.http.get<Organization[]>('/Organization/GetOrganizations', {}).subscribe((result) => {
      this.candidates = 0;
      this.organizations = result;
      this.organizations.forEach((organization) => {
        organization.users.forEach(x => {
          if (x.firstActivation == true) {
            this.candidates++;
          }
        })
      });
    });
  }

  private subscribeOnSignalR() {
    console.log("HeaderComponent");
    this.signalr.connection.on("GetPrivateToken", (token: string) => {
      this.localStorag.set("access_token", token);
    });
    this.signalr.connection.on("SendPrivateUserGet", (user: UserPublicData) => {
      if (this.user.role != Roles.Candidate && user.role == Roles.Candidate) {
        this.user = user;
        this.localStorag.set("user", user);
        this.localStorag.set("role", user.role);
        this.router.navigate(['/waiting-for-submit']);
        this.ref.detectChanges();
      } else if (this.user.role == Roles.Candidate && user.role != Roles.Candidate) {
        this.user = user;
        this.localStorag.set("user", user);
        this.localStorag.set("role", user.role);
        this.router.navigate(['']);
        this.ref.detectChanges();
      } else {
        this.user = user;
        this.localStorag.set("user", user);
        this.localStorag.set("role", user.role);
        this.ref.detectChanges();
      }
    });
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }

  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  addToHomeScreenIOS() {
    let snackBarRef = this._snackBar.openFromComponent(PwaInstallIosComponent,
      {
        duration: 20000
      });
    // let snackBarRef = this._snackBar.open('Message archived');
  }

  userEditPopupDialog(): void {
    const dialogRef = this.dialog.open(UserEditPopupDialog, {
      data: this.user
    });
  }

  documentationComponent(): void {
    const dialogRef = this.dialog.open(DocumentationComponent, {
      data: this.user
    });
  }

  applicationSettingsComponent(): void {
    const dialogRef = this.dialog.open(ApplicationSettingsComponent);
  }

  openSwagger() {
    window.open("/swagger/index.html", "_blank");
  }

  openSmsRu() {
    window.open("https://sms.ru/?panel=login", "_blank");
  }

  reportComponent(): void {
    this.dialog.open(ReportComponent);
  }

  alertsComponent(): void {
    this.dialog.open(AlertsComponent);
  }

  importExportComponent(): void {
    this.dialog.open(ImportExportComponent);
  }

  scheduleComponent(): void {
    this.dialog.open(ScheduleComponent);
  }

  sendMailWithChooseUserComponent(): void {
    this.dialog.open(SendMailWithChooseUserComponent);
  }

  logOut(): void {
    localStorage.clear();
    this.signalr.connection.stop().then(() => {
      var firstLoad: boolean = this.localStorag.get("FirstLoad");
      this.signalr.connection.off("SendPrivateUserGet");
      this.signalr.connection.off("IntegrationKeyGet");
      this.signalr.connection.off("CheckpointPassGet");
      this.signalr.connection.off("OrganizationGet");
      this.signalr.connection.off("UserForOrganizationGet");
      this.localStorag.set("logedIn", false);
      this.localStorag.set("role", Roles.Guest);
      this.localStorag.set("FirstLoad", firstLoad);
      this.appComponent.updateLogedInStatus();
      this.router.navigate(['/entrance']);
      this.appComponent.loading = false;
    });
  }
}

@Component({
  selector: 'user-edit-popup',
  templateUrl: 'user-edit-popup.html',
})
export class UserEditPopupDialog {
  public userChange: UserForChange = {
    id: 0,
    claimedRole: "",
    password: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
    organizationId: 0,
  };

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: UserPublicData, private router: Router, public dialog: MatDialog, public localStorag: LocalStorageService, public signalr: SignalRService) {
    this.publicToChange(data);
    this.signalr.connection.on("SendPrivateUserGet", (user: UserPublicData) => {
      this.localStorag.set("user", user);
      this.publicToChange(user);
      data = user;
    });
  }

  datemask = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];

  private publicToChange(user: UserPublicData) {
    this.userChange.id = user.id;
    this.userChange.organizationId = user.organizationId;
    this.userChange.firstName = user.firstName;
    this.userChange.lastName = user.lastName;
    this.userChange.patronymic = user.patronymic;
    this.userChange.phone = user.phone;
    this.userChange.email = user.email;
  }

  public changeMyAccount(): void {
    this.http.post<any>('/Account/ChangeMyAccount?patronymic=', this.userChange).subscribe();
  }

  public deleteMyAccount(): void {
    this.http.delete<any>("/Account/DeleteMyAccount", {}).subscribe();
    localStorage.clear();
    this.router.navigate(['entrance']);
  }

  cropImagePopupDialog() {
    const dialogRef = this.dialog.open(CropImageUserPopupDialog, {});

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}

import {ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {LyImageCropper, ImgCropperConfig} from '@alyle/ui/image-cropper';
import {StyleRenderer, lyl} from '@alyle/ui';
import {ApplicationSettingsComponent} from '../application-settings/application-settings.component';
import {AlertsComponent} from '../alerts/alerts.component';
import {SendMailComponent} from '../send-mail/send-mail.component';
import {SendMailWithChooseUserComponent} from '../send-mail-with-choose-user/send-mail-with-choose-user.component';
import {ImportExportComponent} from '../import-export/import-export.component';
import {ScheduleComponent} from '../schedule/schedule.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PwaInstallIosComponent} from '../pwa-install-ios/pwa-install-ios.component';
import {HubConnectionState} from '@microsoft/signalr';

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 400px
      height: 300px
    }`,
    flex: lyl`{
      flex: 1
    }`
  };
};

@Component({
  selector: 'crop-image-popup',
  templateUrl: 'crop-image-popup.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class CropImageUserPopupDialog {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: any;
  ready!: boolean;
  @ViewChild(LyImageCropper, {static: true}) readonly cropper!: LyImageCropper;
  result!: string;
  myConfig: ImgCropperConfig = {
    width: 200, // Default `250`
    height: 200, // Default `200`,
    round: true,
    output: {
      width: 250,
      height: 250
    }
  };
  input = new FormData();
  fileToReturn: any = '';

  onCropped(e: any) {
    this.croppedImage = e.dataURL;
    this.fileToReturn = this.base64ToFiles(this.croppedImage, this.croppedImage.name);
    this.input.append("files", this.fileToReturn);
    this.http.post<any>("/Image/UploadMyAvatar", this.input, {}).subscribe();
    this.input = new FormData();
  }

  base64ToFiles(data: any, filename: any | undefined) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  constructor(
    readonly sRenderer: StyleRenderer, public dialog: MatDialog, public http: HttpClient
  ) {

  }
}

interface EditDialog {
  organizations: Organization[],
  user: User,
  organizationName: string
}

interface ForCrop {
  data: User;
  event: any;
}
