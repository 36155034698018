<div class="wrapper" xmlns="http://www.w3.org/1999/html">
<!--  <p *ngIf="newAlert.alertId">{{alerts[alerts.length - 1].title}}</p>-->
  <div class="content">
<div class="users-list__title">Редактор оповещений (Il travaille (peut etre))</div>
    <div class="form__group field">
      <input [(ngModel)]="newAlert.title" type="text" class="form__field" placeholder="Name" name="name"/>
      <label class="form__label">Название</label>
    </div>
    <div class="form__group field">
      <textarea [(ngModel)]="newAlert.text" type="text" class="form__field_textarea" placeholder="Name" name="name"></textarea>
      <label class="form__label">Тема</label>
    </div>
  </div>
</div>
<div class="center">
  <button class="post-btn" (click)="submit()" mat-raised-button color="primary">Опубликовать</button>
</div>
<div class="wrapper">
  <div class="content">
    <app-news *ngFor="let alert of alerts; index as i" (delete)="deleteAlert(i)" (update)="updateAlert(i, alert)"
             (setDate)="setDate(i)" [alert]="alert"></app-news>
<!--      <button class="getReadDate-btn" mat-raised-button color="primary">Опубликовать</button>-->
  </div>
</div>
  <div class="fill"></div>

