import {ReadDate} from "./readDate";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({providedIn: 'root'})
export class ReadDateService {
  constructor(private http: HttpClient){ }

  getReadDate(alertId:number){
    // console.log(readDate);
    // const body = {date: readDate.date};
    return this.http.get<ReadDate>(`/api/ReadDate/set?alertId=${alertId}`);
  }

  delete(id:number) {
    return this.http.delete(`/api/ReadDate/delete${id}`);
  }
}
