import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {LocalStorageService} from '../local-storage.service';
import {Router} from '@angular/router';
import {Organization, OrganizationCreate} from '../models/organization.model';
import {User, UserForChange, UserForRegistration} from '../models/user.model';
import {ImageCroppedEvent, base64ToFile} from 'ngx-image-cropper';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SignalRService} from '../signal-r.service';
import {Roles, RolesForRegistration} from '../models/roles.moel';
import * as _ from "lodash";

import {ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {LyImageCropper, ImgCropperConfig} from '@alyle/ui/image-cropper';
import {StyleRenderer, lyl} from '@alyle/ui';
import {HubConnectionState} from '@microsoft/signalr';

const styles = () => {
  return {
    actions: lyl`{
      display: flex
    }`,
    cropper: lyl`{
      max-width: 400px
      height: 300px
    }`,
    flex: lyl`{
      flex: 1
    }`
  };
};

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  public organizations: Organization[] = [];
  filteredStreets!: Observable<Organization[]>;
  control = new FormControl();


  public roles: string[] = Object.keys(RolesForRegistration);
  public role: string | null = null;
  public tabs: Tabs[] = [];
  myUserData!: User;

  constructor(public dialog: MatDialog, public http: HttpClient, private router: Router, public signalr: SignalRService, public localStorag: LocalStorageService) {
    this.myUserData = this.localStorag.get("user");
    var role = localStorage.getItem("role");
    if (role != null) {
      this.role = role.substring(0, role.length - 1);
      this.role = this.role.substring(1);
    }
    http.get<Organization[]>('/Organization/GetOrganizations').subscribe((result) => {
      this.organizations = result;
      this.pushOrganizations(result);
      console.log(result);
      console.log(this.role);
      this.filteredStreets = this.control.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
  }

  private pushOrganizations(organizations: Organization[]) {
    this.tabs.push({
      name: "Все",
      organizations: organizations
    });
    this.tabs.push({
      name: "Кандидаты",
      organizations: this.parseOrganizationsCandidate(organizations)
    });
    this.tabs.push({
      name: "Деактивированые",
      organizations: this.parseOrganizationsFirstActivation(organizations)
    });
  }

  private parseOrganizationsCandidate(organizations: Organization[]): Organization[] {
    var organizationsList: Organization[] = _.cloneDeep(organizations);
    organizationsList.forEach((x) => {
      x.users = x.users.filter(y => y.firstActivation == true)
    });
    organizationsList = organizationsList.filter(x => x.users.length != 0);
    return organizationsList;
  }

  private parseOrganizationsFirstActivation(organizations: Organization[]): Organization[] {
    var organizationsList: Organization[] = _.cloneDeep(organizations);
    organizationsList.forEach((x) => {
      x.users = x.users.filter(y => y.firstActivation == false && y.role == Roles.Candidate)
    });
    organizationsList = organizationsList.filter(x => x.users.length != 0);
    return organizationsList;
  }

  private update(organizations: Organization[]) {
    this.tabs[0].organizations = organizations.filter(x => x.users.filter(y => y.role == y.claimedRole).length != 0);
    this.tabs[1].organizations = this.parseOrganizationsCandidate(organizations);
    this.tabs[2].organizations = this.parseOrganizationsFirstActivation(organizations);
  }

  private _filter(value: string): Organization[] {
    const filterValue = this._normalizeValue(value);
    var result = this.organizations.filter(x => this._normalizeValue(x.name).includes(filterValue)
      || this._normalizeValue(
        x.users.filter(y => this._normalizeValue(y.firstName).includes(filterValue)).toString()
        || x.users.filter(y => this._normalizeValue(y.lastName).includes(filterValue)).toString()
        || x.users.filter(y => this._normalizeValue(y.patronymic).includes(filterValue)).toString()
        || x.users.filter(y => this._normalizeValue(y.phone).includes(filterValue)).toString()
        || x.users.filter(y => this._normalizeValue(y.email).includes(filterValue)).toString()
        // || x.users.filter(y => this._normalizeValue(y.passQuantity.toString()).includes(filterValue)).toString()
      ));
    this.update(result);
    // this.organizations = result;

    return result;
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public passByUserId(id: number) {
    this.router.navigate(['/my-pass-page/' + id]);
  }

  public activate(user: User): void {
    if (user.role == Roles.Candidate) {
      this.http.get<any>("/Account/ActivateAccount?id=" + user.id, {}).subscribe();
    } else this.http.get<any>("/Account/DesactivateAccount?id=" + user.id, {}).subscribe();
  }

  openDialog(organizationId: number) {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      data: organizationId
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  addOrganizationPopupDialog() {
    const dialogRef = this.dialog.open(AddOrganizationPopupDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  editOrganizationPopupDialog(organization: Organization) {
    const dialogRef = this.dialog.open(EditOrganizationPopupDialog, {
      data: organization
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  CardUserPopupDialog(user: User, organizations: Organization[]) {
    let organizationName: string | undefined;
    for (let organization of organizations) {
      if (user.organizationId == organization.id) organizationName = organization.name;
    }
    const dialogRef = this.dialog.open(CardUserPopupDialog, {
      data: {
        user: user,
        organizations: organizations,
        organizationName: organizationName
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  cropImagePopupDialog(user: User, event: Event) {
    const dialogRef = this.dialog.open(CropImagePopupDialog, {
      data: {
        user: user,
        event: event
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
    this.signalr.onconnected.subscribe(() => {
      if (this.localStorag.get("SignalR") == HubConnectionState.Connected) {
        this.subscribeOnSignalR();
      }
    });

    // if (this.localStorag.get("SignalR") == HubConnectionState.Connected) {
    //   this.subscribeOnSignalR();
    // }
  }

  private subscribeOnSignalR() {
    this.signalr.connection.on("OrganizationGet", (organization: Organization) => {
      console.log(organization);
      for (let i = 0; i < this.organizations.length; i++) {
        if (this.organizations[i].id == organization.id) {
          this.organizations[i].form = organization.form;
          this.organizations[i].address = organization.address;
          this.organizations[i].imgUrl = organization.imgUrl;
          this.organizations[i].name = organization.name;
        } else if (i == this.organizations.length - 1) {
          this.organizations.push(organization);
        }
      }
      this.update(this.organizations);
    });
    this.signalr.connection.on("UserForOrganizationGet", (user: User) => {
      console.log(user);
      for (let i = 0; i < this.organizations.length; i++) {
        if (this.organizations[i].id == user.organizationId) {
          for (let j = 0; j < this.organizations[i].users.length; j++) {
            if (this.organizations[i].users == null) {
              this.organizations[i].users = [];
              this.organizations[i].users.push(user);
              break;
            } else if (this.organizations[i].users[j].id == user.id) {
              this.organizations[i].users[j] = user;
              break;
            } else if (this.organizations[i].users.length == j + 1) {
              this.organizations[i].users.push(user);
              break;
            }

          }
        } else {
          if (this.organizations[i].users == null) continue;
          for (let j = 0; j < this.organizations[i].users.length; j++) {
            if (this.organizations[i].users[j].id == user.id) {
              if (this.organizations[i].users.length == 1) {
                this.organizations[i].users = [];
                break;
              } else {
                this.organizations[i].users.splice(j, 1);
                break;
              }
            }
          }
        }
      }
      this.update(this.organizations);
    });
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
})
export class DialogContentExampleDialog implements OnInit {
  public organizations!: Organization[];
  public roles: string[] = Object.keys(RolesForRegistration);
  public userReg: UserForRegistration = {
    claimedRole: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
    password: "",
    organizationId: 0,
  };

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: number) {
    http.get<Organization[]>('/Organization/GetOrganizations').subscribe((result) => {
      this.organizations = result;
      this.userReg.organizationId = this.data;
      console.log(this.data);
    });
  }

  ngOnInit(): void {
  }

  public addUser(): void {
    this.http.post('/Account/Register', this.userReg, {}).subscribe();
  }
}

@Component({
  selector: 'add-organization-popup',
  templateUrl: 'add-organization-popup.html',
})
export class AddOrganizationPopupDialog {
  constructor(public http: HttpClient) {
  }

  public roles: string[] = Object.keys(RolesForRegistration);
  public organization: OrganizationCreate = {
    id: "0",
    address: "",
    form: "",
    name: "",
    imgUrl: ""
  }

  public addOrganization(): void {
    this.http.post<any>("/Organization/CreateOrganiation", this.organization, {}).subscribe();
  }
}

@Component({
  selector: 'edit-organization-popup',
  templateUrl: 'edit-organization-popup.html',
})
export class EditOrganizationPopupDialog {
  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: Organization, public dialog: MatDialog, public localStorag: LocalStorageService) {
    this.myUserData = this.localStorag.get("user");
  }

  public roles: string[] = Object.keys(RolesForRegistration);
  myUserData!: User;

  public editOrganization(): void {
    this.http.post("/Organization/ChangeOrganiation", this.data, {}).subscribe();
  }

  public deleteOrganization(): void {
    this.http.delete("/Organization/DeleteOrganiation?id=" + this.data.id).subscribe();
  }

  cropImageOrganizationPopupDialog() {
    const dialogRef = this.dialog.open(CropImagePopupDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'card-edit-popup',
  templateUrl: 'card-edit-popup.html',
})
export class CardEditPopupDialog {
  public userReg: UserForChange = {
    id: 0,
    password: '',
    claimedRole: "",
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: "",
    email: "",
    organizationId: 0,
  };


  public Role: string | null = null;
  public Phone: string | null = null;
  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: EditDialog, public dialog: MatDialog) {
    this.userReg.firstName = this.data.user.firstName;
    this.userReg.lastName = this.data.user.lastName;
    this.userReg.patronymic = this.data.user.patronymic;
    this.userReg.phone = this.data.user.phone;
    this.userReg.email = this.data.user.email;
    this.userReg.organizationId = this.data.user.organizationId;
    this.userReg.id = this.data.user.id;
    this.userReg.claimedRole = this.data.user.claimedRole;
    this.userReg.password = this.data.user.password;
    this.Role = localStorage.getItem("role");
    this.Phone = localStorage.getItem("email");
    if (this.Phone) {
    // this.Phone = this.Phone.slice(1);
    this.Phone = this.Phone.slice(1, this.Phone.length - 1)
    }
    console.log('The role is', this.Role, this.Phone, this.userReg.phone);

  }

  public roles: string[] = Object.keys(RolesForRegistration);

  public changeAccount(): void {
    this.http.post<any>('/Account/ChangeAccount', this.userReg, {}).subscribe();
  }

  public deleteAccount(id: number): void {
    this.http.get<any>("/Account/DesactivateAccount?id=" + id, {}).subscribe();
  }

  public onFileChange() {

  }

  cropImagePopupDialog(user: User, event: Event) {
    const dialogRef = this.dialog.open(CropImagePopupDialog, {
      data: {
        user: user,
        event: event
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  protected readonly Roles = Roles;
}

@Component({
  selector: 'card-user-popup',
  templateUrl: 'card-user-popup.html',
})
export class CardUserPopupDialog {
  public role: string | null = null;
  public Phone: string | null = null;

  constructor(public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: EditDialog, public dialog: MatDialog) {
    var role = localStorage.getItem("role");
    this.Phone = localStorage.getItem("email");
    if (this.Phone) {
      this.Phone = this.Phone.slice(1, this.Phone.length - 1);
      console.log("The phone in card-user-popup", this.Phone);
    }
    if (role != null) {
      this.role = role.substring(0, role.length - 1);
      this.role = this.role.substring(1);
    }
  }

  public roles: string[] = Object.keys(RolesForRegistration);

  CardEditPopupDialog() {
    let organizationName: string | undefined;
    for (let organization of this.data.organizations) {
      if (this.data.user.organizationId == organization.id) organizationName = organization.name;
    }
    const dialogRef = this.dialog.open(CardEditPopupDialog, {
      data: {
        user: this.data.user,
        organizations: this.data.organizations,
        organizationName: organizationName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'crop-image-popup',
  templateUrl: 'crop-image-popup.html',
  styleUrls: ['./users-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class CropImagePopupDialog {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: any;
  ready!: boolean;
  @ViewChild(LyImageCropper, {static: true}) readonly cropper!: LyImageCropper;
  result!: string;
  myConfig: ImgCropperConfig = {
    width: 250, // Default `250`
    height: 200, // Default `200`,
    round: false,
    output: {
      width: 250,
      height: 200
    }
  };
  input = new FormData();
  fileToReturn: any = '';

  onCropped(e: any) {
    this.croppedImage = e.dataURL;
    this.fileToReturn = this.base64ToFiles(this.croppedImage, this.croppedImage.name);
    this.input.append("files", this.fileToReturn);
    this.http.post<any>("/Image/UploadAvatarForMyOrganization", this.input, {}).subscribe();
    this.input = new FormData();
  }

  base64ToFiles(data: any, filename: any | undefined) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  constructor(
    readonly sRenderer: StyleRenderer, public dialog: MatDialog, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: Organization
  ) {

  }
}

@Component({
  selector: 'crop-organization-image-popup',
  templateUrl: 'crop-organization-image-popup.html',
  styleUrls: ['./users-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class CropImageOrganizationPopupDialog {
  classes = this.sRenderer.renderSheet(styles);
  croppedImage?: any;
  ready!: boolean;
  @ViewChild(LyImageCropper, {static: true}) readonly cropper!: LyImageCropper;
  result!: string;
  myConfig: ImgCropperConfig = {
    width: 250, // Default `250`
    height: 200, // Default `200`,
    round: false,
    output: {
      width: 250,
      height: 200
    }
  };
  input = new FormData();
  fileToReturn: any = '';

  onCropped(e: any) {
    this.croppedImage = e.dataURL;
    this.fileToReturn = this.base64ToFiles(this.croppedImage, this.croppedImage.name);
    this.input.append("files", this.fileToReturn);
    this.http.post<any>("/Image/UploadAvatarForMyOrganization", this.input, {}).subscribe();
    this.input = new FormData();
  }

  base64ToFiles(data: any, filename: any | undefined) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  constructor(
    readonly sRenderer: StyleRenderer, public dialog: MatDialog, public http: HttpClient, @Inject(MAT_DIALOG_DATA) public data: Organization
  ) {

  }
}

interface Tabs {
  name: string;
  organizations: Organization[];
}

interface EditDialog {
  organizations: Organization[],
  user: User,
  organizationName: string
}

interface ForCrop {
  data: User;
  event: any;
}

interface ForCropOrganization {
  organization: Organization;
  event: any;
}
